<template>
  <v-row dense>
    <v-col>
      <v-card elevation="0" outlined>
        <v-card-title>상담사 관리</v-card-title>
        <v-card-text>
          <v-data-table disable-filtering disable-sort single-select dense
                        :items="items" :headers="headers"
                        :loading="loading" :items-per-page="5" item-key="_id"
                        mobile-breakpoint="0" :footer-props="vs.footerPropsErsTable"
                        @click:row="selectItem"
          >
            <template v-slot:item.role="{item}">
              {{vs.renderRole(item.user.role)}}
            </template>
            <template v-slot:item.workgroup="{item}">
              {{findWorkgroup(item.workgroup)}}
            </template>
            <template v-slot:item.status="{item}">
              {{vs.renderCounselorStatus(item.status)}}
            </template>
            <template v-slot:item.userId="{item}">
              {{vs.maskIdMask(item.userId)}}
            </template>
            <template v-slot:item.name="{item}">
              {{vs.maskName(item.name)}}
            </template>
            <template v-slot:item.phoneNo="{item}">
              {{vs.maskPhone(item.phoneNo)}}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-row>
        <v-col>
          <v-card elevation="0" outlined> <!-- detail -->
            <v-card-subtitle><v-btn small color="warning" @click.stop="setCreateMode">신규등록</v-btn></v-card-subtitle>
            <v-card-text>
              <v-row dense>
                <v-col><v-text-field dense :readonly="!isCreateMode" v-model="selected.userId" label="아이디" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-text-field dense :readonly="!isManager" v-model="selected.name" label="이름" /></v-col>
                <v-col><v-select dense :readonly="!isManager" v-model="selected.user.role" :items="vs.roleOptions" label="권한" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-text-field :readonly="!isManager" dense v-model="selected.position" label="직책" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-text-field dense v-model="selected.phoneNo" label="전화번호" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-text-field dense v-model="selected.extPhoneNo" label="대표번호" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-select :readonly="!isManager" dense v-model="selected.workgroup" :items="workGroupOptions" item-value="_id" item-text="name" label="워킹그룹" /></v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn small color="info" @click.stop="saveItem" :disabled="!selected.userId">저장</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card elevation="0" outlined> <!-- password -->
            <v-card-text v-if="loggedInUser.user.userId == selected.userId">
              <v-row dense>
                <v-col><v-text-field dense type="password" :disabled="isManager" v-model="pwd.current" label="현재 비밀번호" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-text-field dense type="password" v-model="pwd.change" label="변경 비밀번호" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-text-field dense type="password" v-model="pwd.confirm" label="비밀번호 확인" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-btn small color="info" :disabled="!pwd.change || !pwd.confirm || pwd.change !== pwd.confirm" @click.stop="changePassword">비밀번호 변경</v-btn></v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-if="isManager || isCreateMode">
              <v-row dense>
                <v-col><v-text-field dense readonly v-model="pwd.reset" label="비밀번호 초기화" /></v-col>
              </v-row>
              <v-row dense>
                <v-col><v-btn small color="info" :disabled="!selected._id" @click.stop="resetPassword">비밀번호 초기화</v-btn></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import api from '@/services/api'
import vs from '@/utils/viewSupport'
import { mapGetters } from 'vuex'
import notify from '@/utils/notify'

export default {
  name: 'Counselor',
  metaInfo: {
    title: '상담사 관리'
  },
  data() {
    return {
      vs,
      loading: false,
      items: [],
      pwd: {},
      selected: {
        user: {}
      },
      headers: [
        { text: '아이디', value: 'userId' },
        { text: '이름', value: 'name' },
        { text: '권한', value: 'role' },
        { text: '직책', value: 'position' },
        { text: '전화번호', value: 'phoneNo' },
        { text: '대표번호', value: 'extPhoneNo' },
        { text: '소속그룹', value: 'workgroup' },
        { text: '상태', value: 'status' }
      ],
      workGroupOptions: [],
      mode: 'V'
    }
  },
  methods: {
    list() {
      this.loading = true
      api.getCounselors().then(r => { this.items = r.result }).finally(() => { this.loading = false })
    },
    listWorkgroupOptions() {
      api.getWorkgroup().then(r => { this.workGroupOptions = r.result })
    },
    findWorkgroup(id) {
      const wg = this.workGroupOptions.find(e => e._id === id)
      // console.log(wg)
      if (!wg) return '그룹없음'
      return wg.name ? wg.name : id
    },
    selectItem(item) {
      this.selected = item
      this.mode = 'E'
    },
    setCreateMode() {
      this.selected = { user: { role: 'CT' } }
      this.pwd = {
        current: null,
        change: null,
        confirm: null,
        reset: null
      }
      this.mode = 'C'
    },
    saveItem() {
      if (this.selected) {
        this.selected.center = this.loggedInUser.counselor.center
        if (this.isCreateMode) {
          api.postCounselor(this.selected).then(r => {
            notify.showMessage('등록 되었습니다.')
            this.list()
            this.setCreateMode()
          })
        } else {
          api.putCounselor(this.selected._id, this.selected).then(r => {
            notify.showMessage('수정 되었습니다.')
            this.list()
            this.setCreateMode()
          })
        }
      }
    },
    changePassword() {
      api.passwordChange(this.pwd.current, this.pwd.change, this.pwd.confirm).then(r => {
        notify.showMessage('변경 되었습니다.')
      })
    },
    resetPassword() {
      if (this.selected.userId) {
        api.passwordReset(this.selected.userId).then(r => {
          notify.showMessage('리셋 되었습니다.')
          this.pwd.reset = r.result.initPwd
        })
      }
    }
  },
  async mounted() {
    await this.listWorkgroupOptions()
    this.list()
    this.setCreateMode()
  },
  created() {
    this.$store.commit('setAppBarTitle', '상담사 관리')
  },
  computed: {
    ...mapGetters(['loggedInUser', 'role']),
    isViewMode() {
      return this.mode === 'V'
    },
    isCreateMode() {
      return this.mode === 'C'
    },
    isEditMode() {
      return this.mode === 'E'
    },
    isManager() {
      return this.role === 'CD'
    }
  }
}
</script>

<style scoped>
.v-data-table >>> thead tr th {
  padding: 6px !important;
  font-weight: bold !important;
  background-color: black !important;
  color: white !important;
  font-size: 12px !important;
}

.v-data-table >>> tbody tr td {
  padding: 0px 0px 0px 0px !important;
  font-size: 11px !important;
}

>>> div.v-select__selections > div {
  font-size:14px !important;
}

</style>
